import { Component, ElementRef, HostListener, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IChat, IUser } from '@core/interfaces';
import {
  ActivateChat,
  LoadMoreMessage,
  LoadNextPageChats,
  MarkMessagesAsRead,
  MessagingState,
  SendMessage,
  UnsetActiveChat,
  UserState,
} from '@core/states';
import { Select, Store } from '@ngxs/store';
import { animationFrameScheduler, Observable, of } from 'rxjs';
import { delay, filter, switchMap } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Chat, User } from '@core/models';
import { StorageService } from '@auth/services';

@Component({
  selector: 'app-messaging-list',
  templateUrl: './messaging-list.component.html',
  styleUrls: ['./messaging-list.component.scss'],
})
export class MessagingListComponent implements OnInit {
  @Select(MessagingState.chats)
  public chats$: Observable<Chat[]>;

  @Select(MessagingState.activeChat)
  public activeChat$: Observable<Chat>;

  @Select(UserState.user)
  public talent$: Observable<User>;

  @ViewChild('messagesContainer')
  public messagesContainer: ElementRef;

  @ViewChild('messageField')
  public messageField: ElementRef;

  public recipientId: string;
  public userId: string;
  public chatId: string;
  public form = new FormGroup({
    message: new FormControl(null, [Validators.required]),
  });
  public isBusy = false;
  public message = '';
  public messageCover = 'dutch';
  public warningText = '';
  public warningHref = '';
  public enableScrollToBottom = false;
  public showMessageList = false;
  public activeChatUser: IUser = null;
  public activeChat: IChat = null;

  public constructor(
    private store: Store,
    @Inject(MAT_DIALOG_DATA) @Optional() private data: any,
    @Optional() private dialogRef: MatDialogRef<MessagingListComponent>,
    private translate: TranslateService,
    private storageService: StorageService,
  ) {
    if (this.data != null) {
      this.userId = this.data.userId;
      this.recipientId = this.data.recipientId;
    } else {
      this.talent$.subscribe((talent) => {
        if (talent != null) {
          this.userId = talent.id;
        }
      });
    }
    if (this.dialogRef) {
      this.dialogRef
        .afterClosed()
        .pipe(
          switchMap(() => this.store.selectOnce(MessagingState.activeChat)),
          switchMap((chat: IChat) => this.store.dispatch(new MarkMessagesAsRead(chat))),
          switchMap(() => this.store.dispatch(new UnsetActiveChat())),
        )
        .subscribe();
    }
    this.initializeWarningMessage();
  }

  public async initializeWarningMessage(): Promise<void> {
    if (window.innerWidth <= 500) {
      // mobile version
      if (this.storageService.get() === 'caster') {
        this.warningText = await this.translate.get('Bookings outside the platform are not allowed caster').toPromise();
        this.warningHref = 'https://helpdesk.casterbee.nl/voorwaarden-casters/';
      } else {
        this.warningText = await this.translate.get('Bookings outside the platform are not allowed talent').toPromise();
        this.warningHref = 'https://helpdesk.casterbee.nl/regels-talent/';
      }
    } else {
      // desktop version
      if (this.storageService.get() === 'caster') {
        this.warningText = await this.translate.get('Bookings outside the platform are not allowed caster').toPromise();
        this.warningHref = 'https://helpdesk.casterbee.nl/voorwaarden-casters/';
      } else {
        this.warningText = await this.translate.get('Bookings outside the platform are not allowed talent').toPromise();
        this.warningHref = 'https://helpdesk.casterbee.nl/regels-talent/';
      }
    }
  }

  public async ngOnInit(): Promise<void> {
    this.activeChat$
      .pipe(
        filter((chat) => !!chat),
        switchMap((chat) => this.setRecipient(chat)),
        delay(0, animationFrameScheduler),
        switchMap(() => this.scrollConditionally()),
      )
      .subscribe();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.messageCover = event.lang;
    });

    this.messageCover = this.translate.currentLang;
  }

  // Scroll to bottom only when chat active change
  public scrollConditionally(): Observable<void> {
    if (this.enableScrollToBottom) {
      this.enableScrollToBottom = false; // Set it to false after the first scroll
      return this.scrollToBottom(); // Scroll to the bottom only once
    } else {
      return of(); // No scrolling after the first load
    }
  }

  // Scroll function that scrolls to the bottom of the chat
  public scrollToBottom(): Observable<void> {
    if (!this.messagesContainer) {
      return of();
    }
    this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
    return of();
  }

  public async setRecipient(chat: any): Promise<void> {
    this.recipientId = this.userId === chat.recipientId ? chat.senderId : chat.recipientId;
  }

  public onMessageFieldKeyPress(evt: KeyboardEvent): void {
    if (evt.code === 'Enter' && !evt.shiftKey) {
      evt.preventDefault();
      this.sendMessage();
    }
  }

  public onMessageFieldPaste(evt: ClipboardEvent): void {
    evt.preventDefault();
    const text = evt.clipboardData.getData('text/plain');
    //remove html tags
    const cleanText = text.replace(/\n/g, '<br>'); //showing new line in the text
    document.execCommand('insertHTML', false, cleanText);
  }

  public onChangeMessage(value: string): void {
    this.form.setValue({ message: value });
  }

  public async sendMessage(): Promise<void> {
    const activeChat = this.store.selectSnapshot(MessagingState.activeChat);

    if (!this.messageField?.nativeElement.innerHTML || !activeChat) {
      return;
    }

    const chat = this.store.selectSnapshot(MessagingState.activeChat);

    const messageReplaceBr = this.cleanTextFromHTMLTags(this.messageField.nativeElement.innerHTML);

    await this.store.dispatch(
      new SendMessage({
        chatId: chat.id,
        senderId: this.userId,
        recipientId: this.recipientId,
        message: messageReplaceBr,
      }),
    );

    this.message = '';
    this.messageField.nativeElement.textContent = '';
    this.enableScrollToBottom = true;
  }

  public onChatClicked(chat: IChat): void {
    this.enableScrollToBottom = true;

    if (this.activeChat?.id === chat.id) {
      this.activeChatUser = null;
      this.activeChat = null;
      this.store.dispatch(new UnsetActiveChat());
    } else {
      this.activeChatUser = chat.senderId === this.userId ? chat.recipient : chat.sender;
      this.activeChat = chat;
      this.store.dispatch(new ActivateChat(chat.id));
      this.store.dispatch(new MarkMessagesAsRead(chat));
    }
  }

  public goToLink(url: string) {
    window.open(url, '_blank');
  }

  public convertTextToLink(text: string): string {
    if (typeof text !== 'string') return text;

    // Regular expression to match URLs
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

    // Replace URLs in the text with anchor tags
    const linkedText = text.replace(urlPattern, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });

    return linkedText;
  }

  public onScrollDown(): void {
    const activeChat = this.store.selectSnapshot(MessagingState.activeChat);

    if (!!activeChat) {
      setTimeout(() => {
        this.store.dispatch(new LoadNextPageChats());
      }, 500);
    }
  }

  public onScroll(): void {
    const container = this.messagesContainer.nativeElement;
    const scrollTop = container.scrollTop; // Current scroll position
    const scrollHeight = container.scrollHeight; // Total height of content
    const clientHeight = container.clientHeight; // Height of the visible part

    const offset = 30; // Define the offset in pixels

    // Check if the user has scrolled more than the offset from the bottom
    const isScrolledAwayFromBottom = scrollHeight - scrollTop - clientHeight > offset;

    if (isScrolledAwayFromBottom) {
      this.enableScrollToBottom = false;
      // Implement any logic for when the user scrolls away from the bottom
    } else {
      this.enableScrollToBottom = true;
    }
  }

  public loadMoreMessage(): void {
    const activeChat = this.store.selectSnapshot(MessagingState.activeChat);

    if (!!activeChat) this.store.dispatch(new LoadMoreMessage(activeChat.id));
  }

  public cleanTextFromHTMLTags(text: string): string {
    if (!text) return text;

    return text
      .trim()
      .replace(/<br\s*\/?>/gi, '\r\n')
      .replace(/<\/?p>/gi, '\r\n\r\n')
      .replace(/&nbsp;/gi, '')
      .replace(/<\/?[^>]+(>|$)/g, '');
  }
}
